var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"categories-section"},[_c('div',{staticClass:"categories-container"},_vm._l((_vm.categorieslatestArticles),function(category,index){return _c('div',{key:index,staticClass:"category-card"},[_c('div',{staticClass:"category-image-wrapper"},[_c('router-link',{attrs:{"to":{
                    name: 'Article',
                    params: { id: category._id || '' },
                }}},[_c('img',{staticClass:"category-cover",attrs:{"src":_vm.getImageUrl(category.coverImage,'uploads'),"alt":"Category cover"}}),_c('div',{staticClass:"category-info"},[_c('h3',{staticClass:"category-title"},[_vm._v(_vm._s(category.title))]),_c('p',{staticClass:"category-author"},[_c('img',{staticClass:"author-avatar",attrs:{"src":_vm.getImageUrl(_vm.authorAvatars[category.user],'UserImg'),"alt":"Author Avatar"}}),_c('span',[_vm._v(_vm._s(category.author))])])])])],1)])}),0),_c('div',{staticClass:"all-articles-container"},[_vm._l((_vm.paginatedArticles),function(article,index){return _c('div',{key:index,staticClass:"article-card"},[_c('div',{staticClass:"article-cover"},[_c('img',{staticClass:"article-cover-image",attrs:{"src":_vm.getImageUrl(article.coverImage,'uploads'),"alt":"Article cover"}})]),_c('div',{staticClass:"article-details"},[_c('router-link',{attrs:{"to":{
                    name: 'Article',
                    params: { id: article._id || '' },
                }}},[_c('h3',{staticClass:"article-title"},[_vm._v(_vm._s(article.title))]),_c('p',{staticClass:"article-date"},[_vm._v(_vm._s(article.BriefIntroduction))]),_c('p',{staticClass:"article-description"},[_vm._v(_vm._s(article.description))]),_c('p',{staticClass:"article-author"},[_c('img',{staticClass:"author-avatar",attrs:{"src":_vm.getImageUrl(_vm.authorAvatars[article.user],'UserImg'),"alt":"Author avatar"}}),_c('span',[_vm._v(_vm._s(article.author))])])])],1),_c('div',[(_vm.userInformation)?_c('span',{staticClass:"favorite-button",on:{"click":function($event){return _vm.toggleFavorite(article._id)}}},[(article.isFavorite)?_c('img',{attrs:{"src":require(`@/assets/ShouCang-Huang.png`)}}):_c('img',{attrs:{"src":require(`@/assets/ShouCang-Bai.png`)}})]):_c('span')])])}),_c('el-pagination',{attrs:{"background":"","layout":"prev, pager, next","total":_vm.categoriesarticles.length,"page-size":_vm.pageSize,"current-page":_vm.currentPage},on:{"current-change":_vm.handlePageChange}})],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }